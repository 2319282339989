<template>
  <div class="lecture">
    <navbar :fixed="true" :title="title" />
    <div class="lecture-inner">
      <div class="type-tabs">
        <div
          v-for="item in tabs"
          class="tab"
          :key="item.route"
          :class="{ active: isActiveTab(item) }"
          @click="changeType(item)"
        >
          {{ item.label }}
        </div>
      </div>
      <div class="lecture-list">
        <van-list
          v-model="loading"
          class="list-content"
          :finished="finished"
          finished-text="没有更多了"
          @load="fetchList"
        >
          <div
            v-for="item in resultList"
            :key="item.id"
            class="list-item"
            @click="$router.push(`/h5/lecture/${item.id}`)"
          >
            <img :src="item.image" alt="" class="thumb" />
            <div class="right-content">
              <div class="title">{{ item.title }}</div>
              <div class="desc">讲师：<span>曾子文化</span></div>
              <div class="desc">课数：<span>1节</span></div>
              <div class="desc">
                观看次数：<span>{{ item.view_count }}人</span>
              </div>
              <div class="desc">
                发布日期：<span>{{
                  $format(item.cdatetime, "YYYY-MM-DD")
                }}</span>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import { List } from "vant";
import otherApis from "@/apis/other";
import Navbar from "@/components/h5/Navbar";

const TABS = [
  {
    label: "默认",
    route: 0,
    left: "43px"
  },
  {
    label: "最新",
    route: 1,
    left: "43px"
  },
  {
    label: "最热",
    route: 2,
    left: "43px"
  }
];

export default {
  name: "Lecture",
  components: {
    "van-list": List,
    Navbar
  },
  data() {
    return {
      tabs: TABS,
      activeTab: TABS[0],
      page: 1,
      finished: false,
      loading: false,
      resultList: [],
      currApi: otherApis.courseIndex,
      catId: "",
      catList: [],
      title: "课程列表"
    };
  },
  created() {
    // this.fetchList();
    this.fetchCate();
  },
  methods: {
    isActiveTab(tab) {
      return this.activeTab.route === tab.route;
    },
    async fetchCate() {
      this.catId = this.$route.query.cat_id;
      if (!this.catId) return;
      try {
        const {
          data: { course_info }
        } = await otherApis.courseCategoryDetail({ id: this.catId });
        // this.tabs = [
        //   ...TABS,
        //   ...course_cat.map(item => {
        //     return {
        //       label: item.name,
        //       route: item.id
        //     };
        //   })
        // ];
        this.catList = course_info.childData || [];
        this.title = course_info.name;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchList() {
      try {
        const {
          data: { course_list, max_page }
        } = await this.currApi({
          page: this.page,
          cat_id: this.catId,
          order_type: this.orderType
        });
        this.page += 1;
        if (this.page >= max_page) {
          this.finished = true;
        }
        this.resultList = [...this.resultList, ...course_list];
      } catch (error) {
        this.finished = true;
        console.log(error);
      }
      this.loading = false;
    },
    changeType(tab) {
      this.activeTab = tab;
      // this.catId = tab.route;
      this.orderType = tab.route;
      // this.currApi =
      //   type === TYPES.first ? apis.userTeams : apis.secondUserTeams;
      this.refreshList();
    },
    refreshList() {
      this.page = 1;
      this.finished = false;
      this.loading = true;
      this.resultList = [];
      this.fetchList();
    }
  }
};
</script>

<style lang="scss" scope>
.lecture {
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  background-color: #fff;

  .lecture-inner {
    margin: 40px;
    height: 100%;
    /* prettier-ignore */
    // min-height: calc(100vh - 96PX - 40px);
  }

  .sub-menu {
    margin-bottom: 40px;
    font-size: 48px;

    .sub-menu-item {
      color: #333;
      margin-right: 90px;

      &.active {
        color: #ca0a15;
      }
    }
  }

  .type-tabs {
    display: flex;
    margin-bottom: 40px;

    .tab {
      position: relative;
      width: 240px;
      height: 90px;
      background: #f7f7f7;
      border-radius: 10px 10px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 3px;
      margin-right: 10px;
      font-size: 44px;
      color: #8a574d;
      cursor: pointer;

      &.active {
        opacity: 1;
        color: #ffffff;
        background-color: #ca0a15;
      }
    }
  }

  .lecture-list {
    background-color: #fff;
    // padding: 40px;
    // min-height: 600px;

    // min-height: calc(100% - 90px);

    // height: calc(100% - 46px);
    // padding-top: 20px;

    // .list-header {
    //   height: 40px;
    //   background: #ffffff;
    //   border-radius: 4px;
    //   color: #333333;
    //   font-size: 14px;
    //   display: flex;
    //   justify-content: space-evenly;
    //   align-items: center;
    //   padding: 0 20px;
    // }

    .list-content {
      display: flex;
      flex-flow: column nowrap;
      // min-height: 100%;

      // overflow: auto;
      // height: calc(100% - 60px);
      // flex: none;

      .list-item {
        flex: none;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        width: 1055px;
        height: 400px;
        background: #f7f7f7;
        border-radius: 10px;
        margin-top: 40px;
        padding: 20px;
        box-sizing: border-box;

        .thumb {
          height: 360px;
          width: 360px;
          flex: none;
          border-radius: 10px;
        }

        .right-content {
          margin-left: 40px;
          display: flex;
          flex-flow: column nowrap;
          justify-content: space-between;
        }

        .title {
          font-size: 48px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }

        .desc {
          font-size: 36px;
          color: #333;
          overflow: hidden;
          // margin-top: 30px;

          span {
            color: #666;
          }
        }

        &:first-of-type {
          margin-top: 0px;
        }
      }
    }
  }
}
</style>
